import advertiser from '../pictures/apps/weather_hyf.webp';
import advertiser2 from '../pictures/apps/weather_hyf.webp';

const appsData = [
  {
    webp: advertiser,
    png: advertiser2,
    name: 'Weather — how you feel',
    info: 'Weather app without numbers. But with cute graphics and melodies.',
    installLink:
      'https://apps.apple.com/app/weather-how-you-feel/id6739215959',
    infoLink: '/apps/weather',
    policyLink: '/apps/weather/privacy-policy',
    comments: [],
    page: {
      description: '',
      instruction: {},
    },
    meta: {
      content:
        'Weather app without numbers. But with cute graphics and melodies.',
      title: 'Weather — how you feel',
    },
    isNewLabel: true
  },
];

export default appsData;
